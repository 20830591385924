import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class CustomModalService {

  constructor(public modalService: NgbModal) { }

  public CenteredAndStaticBackdropModal(content) {
    let options: NgbModalOptions = {
      backdrop: 'static',
      centered: true,
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title'
      };
      return this.modalService.open(content, {...options});
  }
}
