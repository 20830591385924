import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-my-custom',
  templateUrl: './my-custom.component.html',
  styleUrls: ['./my-custom.component.css']
})
export class MyCustomComponent implements OnInit {

  constructor(private apiService: ApiService, public toastrService: ToastrService) { }

  ngOnInit() {
  }

  addLogoOrganization(rowData, fileName, imageFile) {
    this.apiService.organizationLogoUrl(rowData.id, 'organizationLogos/' + new Date().getTime() + '_' + fileName, 'put').pipe(
      switchMap(url => {
        return this.apiService.presignedUpload(url.toString(), imageFile);
      })).subscribe((res) => {
        this.toastrService.success(`The image is successfully uploaded for: ${rowData.organization_name} organisation.`);
      }, err => {
        this.toastrService.error('Something went wrong. Please try again');
      });
  }

  fileChange(file, rowData) {
    const FILE = (event.target as HTMLInputElement).files[0];
    this.addLogoOrganization(rowData, FILE.name.toString(), FILE);
  }
}
